import { render, staticRenderFns } from "./guide.vue?vue&type=template&id=bab52e4c&scoped=true&lang=html&"
import script from "./guide.vue?vue&type=script&lang=js&"
export * from "./guide.vue?vue&type=script&lang=js&"
import style0 from "./guide.vue?vue&type=style&index=0&id=bab52e4c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bab52e4c",
  null
  
)

export default component.exports